
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "MeasureUnits",

  setup() {
    const store = useStore();
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);
    const dataList = [
      {
        name: "measures_unit",
        route: "/measures_unit",
        icon: "bi bi-rulers",
        count: "",
        permission: "measure unit: index",
      },
      {
        name: "measures_type",
        route: "/measures_type",
        icon: "bi bi-arrows-fullscreen",
        count: "",
        permission: "measures type: index",
      },
      {
        name: "measure_unit_type",
        route: "/measure_unit_type",
        icon: "bi bi-bar-chart-steps",
        count: "",
        permission: "measure unit type: index",
      },
    ];
    const dataObject = ref<[] | any>([]);
    const search = ref("");
    const permissions = ref<[] | any>([]);

    const onSearch = () => {
      dataObject.value = dataList.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const canViewCatalog = (item) => {
      if (JSON.stringify(user) !== "{}") {
        return permissions.value.filter((x) => x.name === item.permission).length > 0;
      }
      return false;
    };

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/permissions/me`)
        .then(({ data }) => {
          permissions.value = data;
        })
        .catch((error) => {
          store.commit("setLoadingStatus", false);
          console.log(error);
        });
      setCurrentPageBreadcrumbs("measures_unit", []);
      dataObject.value = dataList;
      ApiService.get("/api/catalogs/measure-units").then(({ data }) => {
        dataObject.value.forEach((item, index) => {
          item.count = data[index];
          store.commit("setLoadingStatus", false);
        });
      });
    });

    return {
      dataObject,
      dataList,
      search,
      canViewCatalog,
      onSearch,
      permissions,
    };
  },
});
